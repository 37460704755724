export const formatPrice = price => {
  price = price?.replace("zł", "")
  price = price?.replace(",", "")
  return price
}

const mapProductsFormAnalytics = ({ products }) => {
  return products.map(
    ({ name, databaseId, price, paProducents, productCategories }, index) => ({
      name,
      id: databaseId,
      price: formatPrice(price),
      brand: paProducents?.nodes?.map(({ name }) => name).join(",") || "",
      category:
        productCategories?.nodes?.map(({ name }) => name).join(",") || "",
      position: index + 1,
    })
  )
}

export default mapProductsFormAnalytics
