import { gql } from "@apollo/client"

const ADD_TO_CART = gql`
  mutation ($input: AddToCartInput!) {
    addToCart(input: $input) {
      cart {
        total
      }
      cartItem {
        key
        product {
          node {
            id
            databaseId
            name
            description
            type
            onSale
            slug
            averageRating
            reviewCount
            image {
              id
              sourceUrl

              altText
            }
            paProducents {
              nodes {
                name
              }
            }
            productCategories {
              nodes {
                name
              }
            }
            galleryImages {
              nodes {
                id
                sourceUrl

                altText
              }
            }
            ... on VariableProduct {
              price
            }
            ... on SimpleProduct {
              price
            }
          }
        }
        variation {
          node {
            id
            databaseId
            name
            description
            type
            onSale
            price
            regularPrice
            salePrice
            image {
              id
              sourceUrl
              altText
            }
          }
          attributes {
            id
            attributeId
            name
            value
          }
        }
        quantity
        total
        subtotal
        subtotalTax
      }
    }
  }
`

export default ADD_TO_CART
