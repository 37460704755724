/* eslint-disable jsx-a11y/label-has-associated-control */
import "./product-tile.scss"

import React from "react"
import { Link, withPrefix } from "gatsby"
import LazyLoad from "react-lazyload"

import mapProductsFormAnalytics from "utils/mapProductsFormAnalytics"

import { useComparedProducts } from "context/compared_products"
import { useFavoriteProducts } from "context/favorite_products"
import AddToCart from "components/AddToCart/AddToCart"
import LazyPlaceholder from "components/LazyPlaceholder/LazyPlaceholder"

const ProductTile = ({
  id,
  databaseId,
  slug,
  className,
  title,
  price,
  promoPrice,
  lastItems,
  favouriteType,
  stockQuantity,
  image,
  cartConfirmation,
  setCartConfirmation,
  product_data,
  addToCartHidden,
}) => {
  const { local_storage_favorites_products, setProductToFavorites } =
    useFavoriteProducts()
  const { local_storage_compared_products, setProductToCompare } =
    useComparedProducts()

  const handleLinkClick = () => {
    const products = mapProductsFormAnalytics({ products: [product_data] })
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "productClick",
      ecommerce: {
        click: {
          products,
        },
      },
    })
  }

  return (
    <>
      <div className={`product-tile ${className ? className : ""}`}>
        <div
          className={`product-tile__cta${
            promoPrice || lastItems ? " product-tile__cta--with-promo" : ""
          }`}
        >
          {promoPrice && (
            <label className="product-tile__promo-label">Promocja</label>
          )}
          {lastItems && (
            <label className="product-tile__promo-label product-tile__promo-label--last">
              Ostatnie sztuki
            </label>
          )}
          {favouriteType ? (
            <button
              className="product-tile__remove"
              onClick={e => {
                e.preventDefault()
                setProductToFavorites(id)
              }}
            >
              Remove
            </button>
          ) : (
            <div>
              <button
                className={
                  local_storage_compared_products.indexOf(id) > -1
                    ? "is-compared"
                    : ""
                }
                onClick={() => setProductToCompare(id)}
              >
                <img
                  src={
                    require("assets/images/ico-product-tile-clipboard.svg")
                      .default
                  }
                  alt=""
                />
              </button>
              <button
                className={
                  local_storage_favorites_products.indexOf(id) > -1
                    ? "is-favourite"
                    : ""
                }
                onClick={e => {
                  e.preventDefault()
                  setProductToFavorites(id)
                }}
              >
                <img
                  src={
                    require("assets/images/ico-product-tile-heart.svg").default
                  }
                  alt=""
                />
              </button>
            </div>
          )}
        </div>
        <LazyLoad height={231} placeholder={<LazyPlaceholder />}>
          <Link
            to={`/${slug}`}
            className="product-tile__image"
            onClick={handleLinkClick}
          >
            {image ? (
              <img src={image?.localFile?.publicURL} alt={title} />
            ) : (
              <img
                className="product-tile__image--placeholder"
                src={withPrefix("/ico-product-laptop-placeholder.svg")}
                alt={title}
              />
            )}
          </Link>
        </LazyLoad>
        <div>
          <Link to={`/${slug}`} onClick={handleLinkClick}>
            <p className="product-tile__title">{title}</p>
          </Link>
          <div className="product-tile__bottom">
            <div>
              <h4
                className={`product-tile__price${
                  promoPrice ? " product-tile__price--with-promo" : ""
                }`}
              >
                {price}
              </h4>
              {promoPrice && (
                <h4 className="product-tile__promo-price">{promoPrice}</h4>
              )}
            </div>
            {!addToCartHidden && stockQuantity > 0 && (
              <AddToCart
                type="archive"
                databaseId={databaseId}
                cartConfirmation={cartConfirmation}
                setCartConfirmation={setCartConfirmation}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductTile
