/* eslint-disable no-unused-vars */

import React, { useContext } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { v4 } from "uuid"
import { toast } from "react-toastify"

import { AppContext } from "components/Context/AppContext"
import Button from "components/Button/Button"
import { getFormattedCart } from "utils/functions"
import { formatPrice } from "utils/mapProductsFormAnalytics"
import GET_CART from "queries/get-cart"
import ADD_TO_CART from "mutations/add-to-cart"

const AddToCart = ({
  product,
  databaseId,
  quantity,
  disabled,
  type,
  cartConfirmation,
  setCartConfirmation,
  onClick,
}) => {
  const [cart, setCart] = useContext(AppContext)

  const productQtyInput = {
    clientMutationId: v4(),
    productId: product ? product?.databaseId : parseInt(databaseId),
    quantity: quantity ? parseInt(quantity) : 1,
  }

  // Get Cart Data
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const updatedCart = getFormattedCart(data)
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))
      setCart(updatedCart)
    },
    onError: error => {
      console.warn(error)
    },
  })

  // Add to Cart Mutation
  const [addToCart, { loading: addToCartLoading, error: addToCartError }] =
    useMutation(ADD_TO_CART, {
      variables: {
        input: productQtyInput,
      },
      onCompleted: data => {
        const item = data?.addToCart?.cartItem

        if (addToCartError) {
          console.warn(addToCartError.graphQLErrors[0].message)
        } else {
          if (item) {
            window.fbq("track", "AddToCart", {
              content_type: "product",
              content_ids: [item.product.node.databaseId],
              value: formatPrice(item.product.node.price),
              currency: "PLN"
            });
            window.dataLayer.push({ ecommerce: null })
            window.dataLayer.push({
              event: "addToCart",
              ecommerce: {
                currencyCode: "PLN",
                add: {
                  products: [
                    {
                      name: item.product.node.name,
                      id: item.product.node.databaseId,
                      price: formatPrice(item.product.node.price),
                      brand:
                        item.product.node.paProducents?.nodes
                          ?.map(({ name }) => name)
                          .join(",") || "",
                      category:
                        item.product.node.productCategories?.nodes
                          ?.map(({ name }) => name)
                          .join(",") || "",
                      quantity: 1,
                    },
                  ],
                },
              },
            })
          }
        }
        setCartConfirmation(true)
        refetch()
        onClick && onClick()
      },
      onError: error => {
        if (error) {
          error.message ===
          '<a href="https://woo.memtech.pl/koszyk/" class="button wc-forward">Zobacz koszyk</a> Nie możesz dodać takiej ilości do koszyka &mdash; w magazynie posiadamy 1 a w koszyku masz już 1.'
            ? toast.warning("Brak dostępnej ilości sztuk towaru w magazynie.")
            : toast.error("Wystąpił błąd")
          console.warn(error)
        }
      },
    })

  return type === "archive" ? (
    <button
      className="product-tile__add-to-cart"
      type="submit"
      onClick={addToCart}
      disabled={disabled}
    >
      <span>{addToCartLoading ? "Dodawanie..." : "Dodaj do koszyka"}</span>
      <img
        src={require("assets/images/ico-product-tile-cart.svg").default}
        alt=""
      />
    </button>
  ) : (
    <Button type="submit" onClick={addToCart} disabled={disabled}>
      {addToCartLoading ? "Dodawanie..." : "Dodaj do koszyka"}
    </Button>
  )
}

export default AddToCart
